/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./is-login-member.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./is-login-member";
var styles_IsLoginMemberDialogs = [i0.styles];
var RenderType_IsLoginMemberDialogs = i1.ɵcrt({ encapsulation: 0, styles: styles_IsLoginMemberDialogs, data: {} });
export { RenderType_IsLoginMemberDialogs as RenderType_IsLoginMemberDialogs };
function View_IsLoginMemberDialogs_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ion-button", [["color", "primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ok() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(2, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(3, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "ion-button", [["color", "success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(6, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(7, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "primary"; _ck(_v, 2, 0, currVal_0); var currVal_2 = "success"; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("pages.is-member-popup.login-ok")); _ck(_v, 3, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("pages.is-member-popup.login-no")); _ck(_v, 7, 0, currVal_3); }); }
function View_IsLoginMemberDialogs_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ion-button", [["color", "primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss("login") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(2, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(3, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "ion-button", [["color", "success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss("register") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(6, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(7, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "primary"; _ck(_v, 2, 0, currVal_0); var currVal_2 = "success"; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("pages.login.login")); _ck(_v, 3, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("pages.login.register")); _ck(_v, 7, 0, currVal_3); }); }
export function View_IsLoginMemberDialogs_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ionOutlet: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "outer-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "div-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["src", "assets/images/login_earn_b.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "div-btns"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IsLoginMemberDialogs_1)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IsLoginMemberDialogs_2)), i1.ɵdid(15, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.fromGridPage; _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.fromGridPage; _ck(_v, 15, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("pages.is-member-popup.title1")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("pages.is-member-popup.title2")); _ck(_v, 7, 0, currVal_1); }); }
export function View_IsLoginMemberDialogs_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "is-login-member", [], null, null, null, View_IsLoginMemberDialogs_0, RenderType_IsLoginMemberDialogs)), i1.ɵdid(1, 245760, null, 0, i6.IsLoginMemberDialogs, [i1.Injector, i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IsLoginMemberDialogsNgFactory = i1.ɵccf("is-login-member", i6.IsLoginMemberDialogs, View_IsLoginMemberDialogs_Host_0, {}, {}, []);
export { IsLoginMemberDialogsNgFactory as IsLoginMemberDialogsNgFactory };
