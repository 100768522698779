import {Session} from '../data/session';
import CryptoJS from 'crypto-js';
import {NavController} from '@ionic/angular';
import {SessionService} from '../services/session.service';

export class CodeManager {

    code: string;
    params: any;
    view = '';
    today: string;

    constructor(code: string, private sessionService: SessionService) {

        // this.today = this.datePipe.transform((this.menuManager.debugTime ? new Date(this.menuManager.debugTime) : new Date()), 'yyyy-MM-dd');


        if (code.endsWith('%3D')) {
            // code = code.replace("%3D", "=");
            code = decodeURIComponent(code);
        }

        this.code = code;

        console.log('code', this.code);

        this.params = this.code2params(this.code);
        console.log(this.params);

        this.view = this.getView(this.params);

    }

    go(nav: NavController) {


        if (this.view) {

            // erase the code on browser url, and back will never get back to this page
            // history.replaceState({page: "Code"}, null, "#/");

            const session = this.saveSession();
            this.jump2page(nav, session);
        }

    }

    saveSession() {

        const session = new Session();
        session.code = this.code;
        session.date = this.today;

        this.sessionService.setSession(session);

        return session;

    }

    getView(params: any): string {

        const page = params['page'];

        const date = params['date'];
        if (date) {

            if (this.today !== date) {
                return null;
            }
        }


        return page;
    }


    code2params(code: string) {

        const result = {};

        const passcode = '2AUa7u1q48';

        try {

            // var params = atob(this.code);

            const params = this.decrypt(this.url2text(this.code), passcode);

            console.log('decoded', params);
            for (const pair of params.split('&')) {
                console.log(pair);
                const splits = pair.split('=');
                result[splits[0]] = splits[1];

            }

        } catch (ex) {

        }

        return result;
    }

    jump2page(nav: NavController, session: Session) {


        console.log('jumping to', this.view, this.params);

        switch (this.view) {
            case 'byod':

                const mode = this.params['mode'];
                let url = window.location.href.split('?')[0];
                const key = this.params['key'];
                const spot = this.params['spot'];


                this.params['history'] = session;
                url += '#/store/' + this.params['store'];

                if (spot && spot !== '0') {
                    url += '/spot/' + spot;
                }
                if (key) {
                    url += '/key/' + key;
                }

                if (mode) {
                    url += '/mode/' + mode;
                }

                console.log('forward url', url);

                window.location.replace(url);

                break;
            default:
                this.view = null;
                break;
        }

    }

    decrypt(data: string, passcode: string): string {
        return CryptoJS.Rabbit.decrypt(data, passcode).toString(CryptoJS.enc.Utf8);
    }

    url2text(text: string) {
        return text.replace(/_/g, '/').replace(/-/g, '+');
    }
}
