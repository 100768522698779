var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { MobileBasePage } from '../../core/base/mobile-base-page';
var AddressDialog = /** @class */ (function (_super) {
    __extends(AddressDialog, _super);
    function AddressDialog(injector, cd) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.cd = cd;
        // addressArr = [];
        _this.addressArr = [];
        _this.pickupAddressGroups = [];
        _this.pickupArr = [];
        _this.isRemove = false;
        _this.willRemoveAddressArr = []; // save the arr of address:Address(will removing)
        _this.storeLocation = _this.orderService.store.location;
        _this.isRoute = _this.getNavParams('isRoute') || false;
        _this.loginCb = _this.getNavParams('loginCb');
        _this.orderItem = _this.getNavParams('orderItem');
        _this.charge = _this.getNavParams('charge');
        _this.addAddressCb = _this.getNavParams('addAddressCb');
        _this.getAddresses();
        return _this;
    }
    AddressDialog.prototype.ngOnInit = function () {
        this.addressArr = this.addressManagerService.addressArr;
        console.log('AddressDialog -> ngOnInit -> this.addressArr', this.addressArr);
        this.currentAddress = this.addressManagerService.currentAddress;
        // todo 暂时pickup地址为store location
        this.addressManagerService.initPickupArr();
        this.pickupAddressGroups = this.addressManagerService.pickupArr;
        // this.addressArr = [{
        //   name: 'heheh',
        // }, {
        //   name: 'ggg'
        // }, {
        //   name: 'fff'
        // }];
    };
    AddressDialog.prototype.selectAddressChange = function (ev) {
        // console.log('selectAddressChange ev', ev, this.currentAddress);
        if (!this.currentAddress)
            return;
        this.addressManagerService.setPickup(false);
        this.pickupAddress = null;
    };
    AddressDialog.prototype.removeAddressArrChange = function (i) {
        var _this = this;
        console.log('removeAddressArrChange', i);
        console.log('removeAddressChange willRemoveAddressArr:', this.willRemoveAddressArr);
        var val = this.willRemoveAddressArr.indexOf(this.addressArr[i]);
        if (val === -1) {
            // select new address (will removing)
            this.willRemoveAddressArr.push(this.addressArr[i]);
        }
        else {
            // cancel the selecting address(will removing)
            this.willRemoveAddressArr = this.willRemoveAddressArr.filter(function (el) { return el.id !== _this.addressArr[i]['id']; });
        }
        console.log('removeAddressChange willRemoveAddressArr:', this.willRemoveAddressArr);
    };
    AddressDialog.prototype.getAddresses = function () {
        var _this = this;
        this.loading(true);
        this.addressManagerService.getAddresses(this.orderService.store).subscribe(function (addres) {
            _this.loading(false);
            console.log(addres);
            _this.addressArr = addres;
        }, function (err) {
            _this.loading(false);
            return _this.showAlert(err.status, err['error']);
        });
    };
    AddressDialog.prototype.addNewAdress = function () {
        var _this = this;
        console.log('addNewAdress cliking', this.addAddressCb);
        var parmas = {};
        if (this.addAddressCb)
            parmas['addAddressCb'] = this.addAddressCb;
        parmas['getAddress'] = function () { return _this.getAddresses(); };
        this.pushByName('AddAddressPage', parmas, {}).then(function () {
        });
    };
    AddressDialog.prototype.calculate = function (address) {
        var _this = this;
        this.loading(true);
        this.addressManagerService.calculateAddress(address, this.orderService.store, this.orderItem).subscribe(function (result) {
            _this.loading(false);
            if (result && result.couriers && result.couriers.length < 1) {
                _this.showAlert('fail', 'no couriers provide');
                return;
            }
        }, function (err) {
            _this.loading(false);
            return _this.showAlert(err.status, err['error']);
        });
    };
    AddressDialog.prototype.closeClicked = function () {
        var _this = this;
        if (this.isRoute) {
            // 路由导航 from the delivery-login registe successly
            if (this.isRemove) {
                this.isRemove = false;
            }
            else
                this.popToRoot().then(function () {
                    if (_this.loginCb)
                        _this.loginCb();
                });
        }
        else {
            // modal
            if (!this.isRemove)
                this.modalController.dismiss({});
            else {
                this.isRemove = false;
            }
        }
    };
    AddressDialog.prototype.okClicked = function () {
        var _this = this;
        if (this.addressManagerService.isPickup) {
            console.log('选定 pick up', this.pickupAddress);
            // update the addressManagerService pickupAddress
            this.addressManagerService.setPickupAddress(this.pickupAddress);
            if (this.isRoute) {
                this.popToRoot();
            }
            else
                this.modalController.dismiss({});
        }
        else {
            console.log('选定 delivery address', this.currentAddress);
            var address = this.addressManagerService.findAddress(this.currentAddress);
            console.log('findAddress by index success ,address', address);
            // save the ordering-time address
            if (address) {
                this.calculate(address);
                this.addressManagerService.saveCurrentAddress(address);
            }
            else {
                console.log('not find the current addressId of Address');
                return;
            }
            if (this.isRoute) {
                // todo
                // 1. if currentAddress is valid push to cat-list-page with login
                this.popToRoot().then(function () {
                    if (_this.loginCb)
                        _this.loginCb();
                });
                // 2. if invalid push to cat-list-page with not login
            }
            else
                this.modalController.dismiss({});
        }
    };
    AddressDialog.prototype.removeClicked = function () {
        var _this = this;
        console.log('removeClicked willRemoveAddressArr', this.willRemoveAddressArr);
        if (this.willRemoveAddressArr.length < 1) {
            console.log('it is not select item which will be removing');
        }
        else {
            // todo call api to remove the address
            this.willRemoveAddressArr.forEach(function (el) {
                // find the removing addressId according to the indexArr
                var address = _this.addressManagerService.findAddress(el);
                var addressId = address['id'];
                // if delete-address is currentAddress , so need to update currentAddress;
                if (_this.currentAddress && addressId === _this.currentAddress['id']) {
                    _this.addressManagerService.saveCurrentAddress(null);
                    _this.addressManagerService.couriers = [];
                }
                _this.postRemoveAddress(addressId);
            });
        }
    };
    AddressDialog.prototype.setCurrentAddressNull = function () {
        console.log('setCurrentAddressNull');
        this.addressManagerService.setPickup(true);
        this.currentAddress = null;
    };
    AddressDialog.prototype.setPickupAddressNull = function () {
        this.addressManagerService.setPickup(false);
        this.pickupAddress = null;
    };
    AddressDialog.prototype.postRemoveAddress = function (addressId) {
        var _this = this;
        if (addressId)
            this.addressManagerService.deleteAddress(addressId).subscribe(function (result) {
                console.log('deleteAddress() result:', result);
                _this.addressArr.map(function (ad, i) { return ad['id'] === addressId && _this.addressArr.splice(i, 1); });
                _this.addressManagerService.addressArr = _this.addressArr;
                _this.isRemove = false;
            }, function (err) {
                _this.loading(false);
                return _this.showAlert(err.status, err['error']);
            });
    };
    AddressDialog.prototype.removeHandle = function () {
        console.log('removeHandle clicking');
        this.isRemove = true;
        this.cd.detectChanges();
    };
    AddressDialog.prototype.trackAddressById = function (index, address) {
        return address.id;
    };
    return AddressDialog;
}(MobileBasePage));
export { AddressDialog };
