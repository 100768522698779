import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';

import {
    ActionSheetController,
    AlertController,
    IonRouterOutlet,
    MenuController,
    ModalController,
    NavController,
    Platform
} from '@ionic/angular';

import {Data, MenuManager} from 'aigens-ng-core';
import {SessionService} from './core/services/session.service';
import {BrowserService} from './core/services/browser.service';
import {TranslateService} from '@ngx-translate/core';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router
} from '@angular/router';
import {CodeManager} from './core/managers/code-manager';
import {NavigationService} from './core/services/navigation.service';
import {version} from '../../package.json';
import {ConfigService} from './core/services/config.service';
import {VersionCheckService} from './core/services/check-version.service';
import {filter} from 'rxjs/operators';
import {SdkConfigService} from './core/services/sdk-config-service';
import {GoogleAnalyticsService} from "./core/services/google-analytics.service";

declare var genkiJSInterface: any;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

    // @ViewChild(Nav) nav: Nav;


    // rootPage: any; // = ByodBrandSplashPage;

    // pages: any[];
    redirect: string;
    isWechat: boolean;
    isFacebook: boolean;
    // backByBrowser = false;
    // backByApp = false;
    // firstTimeLoading = true;
    public version: string = version;
    @ViewChild(IonRouterOutlet) routerOutlet: IonRouterOutlet;
    private pageLoaded: EventEmitter<string> = new EventEmitter<string>();

    constructor(public configService: ConfigService, private navController: NavController, public menuManager: MenuManager,
                private navigationService: NavigationService, private route: ActivatedRoute,
                private alertController: AlertController, private translate: TranslateService,
                private menu: MenuController, private sessionService: SessionService,
                public router: Router, public platform: Platform, public modalController: ModalController,
                public versionCheckService: VersionCheckService, public gaService: GoogleAnalyticsService,
                public actionSheetCtrl: ActionSheetController, public browserService: BrowserService, public sdkConfigService: SdkConfigService) {

        this.configService.app = this;
        console.warn('byod version', version);
        if (window.location.href.match(/\/#/) && navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
            window.location.replace(window.location.href.replace('/#', ''));
            return;
        }

        this.isWechat = this.browserService.isWeChat();
        this.isFacebook = this.browserService.isFacebook();

        this.pageLoaded.subscribe(data => {
            this.sendGAPage(data);
        });
    }

    ngOnInit(): void {
        this.configRootUrl();
        this.initializeApp();
        this.initTranslation();
        // this.versionCheckService.initVersionCheck(environment.versionCheckURL);
        this.configService.setUserToken();

        console.log('register listener');
        this.route.queryParams.subscribe(params => {
            if (params && params['time']) {
                this.menuManager.setDebugTime(Number.parseInt(params['time']));
                this.configService.setDebugTime(Number.parseInt(params['time']));
            }
        });
        this.router.events.pipe(filter(event => (event instanceof NavigationStart) || (event instanceof NavigationCancel) || (event instanceof NavigationEnd) || (event instanceof NavigationError))).subscribe(event => {
            console.log('on router event', event);
            if (event instanceof NavigationStart) {
                this.navigationService.navigating = true;
                //  Handle the old style hash url
                if (!!event.url && event.url.match(/^\/#/)) {
                    this.router.navigate([event.url.replace('/#', '')]);
                }
                if (!this.navigationService.getRootPageUrl()) {
                    console.log('current root url is: ', this.navigationService.getRootPageUrl());
                    this.configRootUrl();
                }
            } else if (event instanceof NavigationError) {
                // this.navigationService.navigating = false;
                console.log('on navigation error, redirect to ', this.navigationService.getRootPageUrl());
                this.router.navigate([this.navigationService.getRootPageUrl()]);
            } else if (event instanceof NavigationEnd) {
                console.log('on navigation end');
                // this.sendGAPage(event);
                this.pageLoaded.emit(event.urlAfterRedirects);
                // gtag('set', 'page', event.urlAfterRedirects);
                // gtag('send', 'pageview');
                this.navigationService.navigating = false;
                this.navigationService.poping = false;
                this.modalController.getTop().then(modal => {
                    if (modal) {
                        modal.dismiss();
                    }
                });
                this.alertController.getTop().then(alert => {
                    if (alert) {
                        alert.dismiss();
                    }
                });
                this.actionSheetCtrl.getTop().then(actionSheet => {
                    if (actionSheet) {
                        actionSheet.dismiss();
                    }
                });
            } else if (event instanceof NavigationCancel) {
                console.log('on navigation cancel');
                this.navigationService.navigating = false;
                this.navigationService.poping = false;
                this.modalController.getTop().then(modal => {
                    if (modal) {
                        modal.dismiss();
                    }
                });
                this.alertController.getTop().then(alert => {
                    if (alert) {
                        alert.dismiss();
                    }
                });
                this.actionSheetCtrl.getTop().then(actionSheet => {
                    if (actionSheet) {
                        actionSheet.dismiss();
                    }
                });
            }
        });
    }

    checkSupported(): boolean {

        /*
        var country = this.configService.getCountry();

        console.log(country);

        if (country == "cn" && !this.configService.isLocalhost()) {

            if (!this.browserService.isWeChat()) {
                this.redirectPage("wechat");
                return false;
            } else if (this.browserService.isIonicRoot()) {

                this.redirectPage("wechat");
                return false;

            }


        }*/

        return true;

    }

    initTranslation() {


        const locale = this.configService.getLocale();

        console.log('default lang', locale);
        this.translate.setDefaultLang(locale);
        this.translate.currentLang = locale;
        this.configService.setLocale(locale);


    }

    // TODO: only used in CodePage

    // redirectPage(target: string) {
    //   this.redirect = target;
    //   this.
    //   this.nav.setRoot(RedirectPage, { target: target });
    // }

    initializeApp() {

        this.platform.ready().then(() => {

                const supported = this.checkSupported();

                console.log('browser supported', supported);

                if (supported) {

                    this.processForward();
                    this.setupBackButtonBehavior();
                    // this.autoRegisterBrowserState();

                }


                // Overwrite log to prevent large objects in log
                if (this.configService.config && this.configService.config.default && this.configService.config.default.production && window.location.host !== 'prerelease.order.place') {
                    // console.log = () => {
                    // };
                }

                const imgHost = this.configService.get('imageHost');
                Data.imageHost = imgHost;
                // this.menuController.swipeEnable(false, 'menu1');


                this.sdkConfigService.getParams((result) => {
                    console.log('JJ: AppComponent -> initializeApp -> result', result);
                    this.sdkConfigService.sdkConfig = result;
                });
            }
        );
    }

    // TODO: use router guard to decode and guide?
    decodeForward(code: string) {
        const cm = new CodeManager(code, this.sessionService);
        // cm.go(this.nav);
    }

    processForward() {

        console.log(window.location);

        const hash = window.location.hash;
        const search = window.location.search;
        const href = window.location.href;
        const values = null;
        const params = {};
        // let name;

        // get wechat openId
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('openId')) {
            this.configService.openId = searchParams.get('openId');
        }

        if (search && search.indexOf('?code') === 0) {

            const code2 = search.substring(6, search.length);
            console.log('code page2!', code2);
            this.decodeForward(code2);
        } else if (!hash) {
            // this.nav.push(ByodBrandSplashPage);

        } else if (hash.indexOf('#/code/') === 0) {

            const code = hash.substring(7, hash.length);
            console.log('code page!', code);

            this.decodeForward(code);


        } else if (hash.indexOf('#/store/') === 0) {

        } else if (hash.indexOf('#/brand/') === 0) {

        } else if (hash.indexOf('#/login') === 0) {

        } else if (hash.indexOf('#/history/') === 0) {

            /*
            var values:any = hash.substring(2).split("/");


            var params = {};
            var name;
            for (let v of values) {
                if (!name) name = v;
                else {
                    params[name] = v;
                    name = null;
                }
            }

            this.nav.push(EmailOrderSummary, params);
            */

        }

    }

    // TODO: handle genki interface
    setupBackButtonBehavior() {

        /*   // If on web version (browser)
           if (window.location.protocol !== 'file:') {

             // Register browser back button action(s)

             window.onpopstate = (evt) => {

               console.log('onpop', evt);

               // var state = evt.state;
               // if (!state) return true;

               // Navigate back
               const modal = this.ionicApp._modalPortal.getActive();
               const needToShowAlert = (!this.nav.canGoBack() && this.nav.getActive(true).instance instanceof DirectCategoryItemListPage) ||
                   (this.nav.getActive(true).instance instanceof CategoryItemListPage && this.browserService.isBKApp());


               this.backByBrowser = true;
               if (modal && !this.backByApp) {
                 modal.dismiss();
               } else if (this.nav.canGoBack() && !needToShowAlert && !this.backByApp) {

                 this.nav.pop();
               } else if (needToShowAlert && !this.backByApp) {
                 const exitAlert = this.alertController.create({
                   title: 'The cart will be cleared.<br>Do you want to leave this page?',
                   buttons: [{
                     text: 'Cancel',
                     role: 'cancel',
                     handler: () => {
                       history.pushState({ page: 'DirectCategoryItemListPage' }, null, '');
                     }
                   }, {
                     text: 'OK',
                     handler: () => {
                       genkiJSInterface.exitwebview();
                     }
                   }]
                 });

                 exitAlert.onDidDismiss(() => {
                   if (genkiJSInterface.hasAlert) {
                     genkiJSInterface.hasAlert(false);
                   }
                 });

                 if (genkiJSInterface.hasAlert) {
                   genkiJSInterface.hasAlert(true);
                 }

                 exitAlert.present();
               }
               setTimeout(() => {
                 this.backByBrowser = false;
               }, 1000);


             };

           }*/

    }

    private configRootUrl() {
        console.log('config root url in app.component');
        this.navigationService.configRootUrl();
    }


    private sendGAPage(urlAfterRedirects) {
        let temp = urlAfterRedirects;
        console.log('event:', temp);
        console.log('root:', this.navigationService.getRootPageUrl());
        if (temp.includes(this.navigationService.getRootPageUrl())) {
            temp = temp.slice(this.navigationService.getRootPageUrl().length + 1);
        }
        this.gaService.pageViewTrack(this.configService.GAsuffix + temp);

    }
}
