import {Injectable, NgZone} from '@angular/core';
import {BaseService} from '../base/base-service';

const GROUP_NAME = 'myGroup';

@Injectable({providedIn: 'root'})
export class GoogleAnalyticsService extends BaseService {

    trackerName = new Set<string>();
    trackerIds = new Set<string>();

    constructor(public zone: NgZone) {
        super();
        // init default gtag in index
        // this.createTracker();
        (<any>window).dataLayer = (<any>window).dataLayer || [];

        (<any>window).gtag = function () {
            (<any>window).dataLayer.push(arguments);
        }
    }

    // createTracker() {
    //     const defaultId = GoogleAnalyticsService.getMainTrackerId();
    //     console.log('init default gtag', defaultId);
    //     this.createTrackerById(defaultId, 'Aigens');
    // }

    createTrackerById(trackId, trackerName) {
        console.log('init gtag', trackId + ':' + trackerName);
        if (!!(<any>window).gtag) {
            if (this.trackerIds.has(trackId)) {
                console.log('creating existing id, reject');
                return;
            }
            console.log('create gtag: ', trackerName);
            (<any>window).gtag('config', trackId, { 'send_page_view': true});
        } else {
            console.warn('gtag is undefined, cannot create gtag tracker');
        }
    }

    pageViewTrack(pagePath: string,) {
        if (!!(<any>window).gtag) {
            this.zone.runOutsideAngular(() => {
                (<any>window).gtag('event', 'page_view', {
                    // 'send_to': GROUP_NAME,
                    'page_title': pagePath,
                    'path_path': pagePath
                });
            })
        } else {
            console.warn('gtag is undefined, cannot send  page view');
        }
    }

    eventTrack(page: string, event: string, label: string) {
        if (!!(<any>window).gtag) {
            (<any>window).gtag('event', event, {
                // 'send_to': GROUP_NAME,
                'event_label': label,
                'event_category': page
            });
        } else {
            console.warn('gtag is undefined, cannot send event');
        }

    }

    newEventTrack(event: string, valueMap: {}) {
        if (!!(<any>window).gtag) {
            this.zone.runOutsideAngular(() => {
                console.log('gtag event track ', event);
                // valueMap['send_to'] = GROUP_NAME;
                (<any>window).gtag('event', event, valueMap);
            })
        } else {
            console.warn('gtag is undefined, cannot send event');
        }

    }

    setDimensions(dimensions) {
        if (!!(<any>window).gtag) {
            this.trackerIds.forEach(id => {
                (<any>window).gtag('config', id, {'custom_map': dimensions});
            })
        }

    }
}
