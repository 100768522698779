import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import { ExternalWebPage } from './external-web-page';

@NgModule({
    declarations: [ExternalWebPage],
    imports: [
        SharedModule
    ]
})
export class ExternalWebModule {
}
