import { OrderManager } from 'aigens-ng-core';
import { ConfigService } from './config.service';
import { AQuery } from '../base/aquery';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
import * as i3 from "aigens-ng-core/dist/manager/order-manager";
var AddressManagerService = /** @class */ (function () {
    function AddressManagerService(http, configs, orderManager, configService) {
        this.http = http;
        this.configs = configs;
        this.orderManager = orderManager;
        this.configService = configService;
        this.couriers = [];
        this.isDelivery = true; // the switch of the delivery-feature
        this.isPickup = false; // the mark of pick up
        this.pickupArr = [];
        this.note = ''; // delivery order remarks
        this.aq = new AQuery(http, configs);
        this.addressArr = [];
    }
    AddressManagerService.prototype.getAddresses = function (store) {
        var _this = this;
        var url = '/api/v1/store/address.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.auth(true);
        return aq.getJson().pipe(map(function (jo) {
            var addressArr = jo['data'];
            addressArr.map(function (a, i) {
                var distance = _this.calDistance(a.latitude, a.longitude, store);
                console.log('距离', distance);
                addressArr[i]['beyond'] = distance > 5; // 超出5公里
            });
            _this.addressArr = addressArr;
            return addressArr;
        }));
    };
    AddressManagerService.prototype.calculateAddress = function (address, store, oi, courierId) {
        var _this = this;
        var url = '/api/v1/menu/calculate.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.auth(true);
        var params = {
            addressId: address['id'],
            phone: address['phone'],
            storeId: store['id'],
            type: 'delivery'
        };
        if (oi)
            params['orderItem'] = oi;
        if (courierId)
            params['courierId'] = courierId;
        aq.body = params;
        return aq.getJson().pipe(map(function (jo) {
            if (jo['data'] && jo['data'].couriers) {
                console.log('AddressManagerService -> couriers', jo['data'].couriers);
                _this.couriers = jo['data'].couriers;
                if (!courierId && _this.couriers && _this.couriers.length < 2) {
                    // select default when only one
                    _this.currentCourier = _this.couriers[0];
                }
            }
            return jo['data'];
        }));
    };
    AddressManagerService.prototype.postAddress = function (address) {
        var url = '/api/v1/store/address.json';
        var aq = this.aq;
        var params = address;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;
        aq.auth(true);
        return aq.getJson().pipe(map(function (jo) {
            return jo['data'];
        }));
    };
    AddressManagerService.prototype.deleteAddress = function (addressId) {
        var _this = this;
        if (!addressId)
            return;
        var url = "/api/v1/store/address/" + addressId + ".json";
        var aq = this.aq;
        aq.url = url;
        aq.method = 'delete';
        aq.auth(true);
        return aq.getJson().pipe(map(function (jo) {
            // local data update
            var index = _this.addressArr.findIndex(function (value) {
                if (value['id'] === addressId) {
                    return true;
                }
                else {
                    return false;
                }
            });
            _this.addressArr.splice(index, 1);
            return jo['data'];
        }));
    };
    AddressManagerService.prototype.getAddressAmount = function () {
        return this.addressArr.length;
    };
    AddressManagerService.prototype.saveCurrentAddress = function (address) {
        this.currentAddress = address;
        this.configService.putPreference('deliveryAddress', this.currentAddress);
        console.log('saveCurrentAddress success, currentAddress:', this.currentAddress);
    };
    AddressManagerService.prototype.getCurrentAddress = function () {
        return this.currentAddress || this.findDefaultAddress();
    };
    AddressManagerService.prototype.calDistance = function (lat2, lon2, s, unit) {
        if (unit === void 0) { unit = 'K'; }
        if (!s && !s.location && !s.location.latitude && !s.location.longitude)
            return;
        var lat1 = s.location.latitude;
        var lon1 = s.location.longitude;
        console.log('维度', lat1, lon1, lat2, lon2);
        if ((lat1 === lat2) && (lon1 === lon2)) {
            return 0;
        }
        else {
            var radlat1 = Math.PI * lat1 / 180;
            var radlat2 = Math.PI * lat2 / 180;
            var theta = lon1 - lon2;
            var radtheta = Math.PI * theta / 180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit === 'K') {
                dist = dist * 1.609344;
            }
            if (unit === 'N') {
                dist = dist * 0.8684;
            }
            return dist;
        }
    };
    // bind the attr in address-dialog-ridio is what ,this filter function using attr is what ;
    AddressManagerService.prototype.findAddress = function (addr) {
        var arr = this.addressArr.find(function (address) { return address['id'] === addr['id']; });
        return arr;
    };
    // get the local delivery address from the addressArr
    AddressManagerService.prototype.findDefaultAddress = function () {
        var _this = this;
        return this.configService.getPreference('deliveryAddress').then(function (localAddress) {
            var arr = _this.addressArr.filter(function (address) { return !address['beyond']; });
            if (localAddress) {
                return localAddress;
            }
            return arr.length > 0 ? arr[0] : null;
        });
    };
    // pick up
    AddressManagerService.prototype.setPickup = function (bool) {
        this.isPickup = bool;
    };
    AddressManagerService.prototype.initPickupArr = function () {
        // todo call api to get pick up address
        // now hardcode current store
        this.pickupArr = [];
        this.pickupArr.push(this.orderManager.store.location);
    };
    AddressManagerService.prototype.getPickupAddress = function () {
        return this.isPickup ? (this.pickupAddress || null) : false;
    };
    AddressManagerService.prototype.setPickupAddress = function (obj) {
        this.pickupAddress = obj;
    };
    // return: if select delivery address and pick up address
    AddressManagerService.prototype.isSetAddress = function () {
        return this.currentAddress || this.pickupAddress;
    };
    AddressManagerService.prototype.clear = function () {
        this.currentCourier = null;
        this.note = '';
    };
    AddressManagerService.prototype.clearAll = function () {
        // logout
        this.addressArr = [];
        // this.addressArrOfStoreList = [];
        this.isPickup = false;
        this.currentAddress = null; // save the order using takeout-address
        this.couriers = [];
        this.currentCourier = null;
        this.pickupAddress = null;
    };
    AddressManagerService.ngInjectableDef = i0.defineInjectable({ factory: function AddressManagerService_Factory() { return new AddressManagerService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService), i0.inject(i3.OrderManager), i0.inject(i2.ConfigService)); }, token: AddressManagerService, providedIn: "root" });
    return AddressManagerService;
}());
export { AddressManagerService };
