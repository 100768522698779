var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgZone } from '@angular/core';
import { BaseService } from '../base/base-service';
import * as i0 from "@angular/core";
var GROUP_NAME = 'myGroup';
var GoogleAnalyticsService = /** @class */ (function (_super) {
    __extends(GoogleAnalyticsService, _super);
    function GoogleAnalyticsService(zone) {
        var _this = _super.call(this) || this;
        _this.zone = zone;
        _this.trackerName = new Set();
        _this.trackerIds = new Set();
        // init default gtag in index
        // this.createTracker();
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
            window.dataLayer.push(arguments);
        };
        return _this;
    }
    // createTracker() {
    //     const defaultId = GoogleAnalyticsService.getMainTrackerId();
    //     console.log('init default gtag', defaultId);
    //     this.createTrackerById(defaultId, 'Aigens');
    // }
    GoogleAnalyticsService.prototype.createTrackerById = function (trackId, trackerName) {
        console.log('init gtag', trackId + ':' + trackerName);
        if (!!window.gtag) {
            if (this.trackerIds.has(trackId)) {
                console.log('creating existing id, reject');
                return;
            }
            console.log('create gtag: ', trackerName);
            window.gtag('config', trackId, { 'send_page_view': true });
        }
        else {
            console.warn('gtag is undefined, cannot create gtag tracker');
        }
    };
    GoogleAnalyticsService.prototype.pageViewTrack = function (pagePath) {
        if (!!window.gtag) {
            this.zone.runOutsideAngular(function () {
                window.gtag('event', 'page_view', {
                    // 'send_to': GROUP_NAME,
                    'page_title': pagePath,
                    'path_path': pagePath
                });
            });
        }
        else {
            console.warn('gtag is undefined, cannot send  page view');
        }
    };
    GoogleAnalyticsService.prototype.eventTrack = function (page, event, label) {
        if (!!window.gtag) {
            window.gtag('event', event, {
                // 'send_to': GROUP_NAME,
                'event_label': label,
                'event_category': page
            });
        }
        else {
            console.warn('gtag is undefined, cannot send event');
        }
    };
    GoogleAnalyticsService.prototype.newEventTrack = function (event, valueMap) {
        if (!!window.gtag) {
            this.zone.runOutsideAngular(function () {
                console.log('gtag event track ', event);
                // valueMap['send_to'] = GROUP_NAME;
                window.gtag('event', event, valueMap);
            });
        }
        else {
            console.warn('gtag is undefined, cannot send event');
        }
    };
    GoogleAnalyticsService.prototype.setDimensions = function (dimensions) {
        if (!!window.gtag) {
            this.trackerIds.forEach(function (id) {
                window.gtag('config', id, { 'custom_map': dimensions });
            });
        }
    };
    GoogleAnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleAnalyticsService_Factory() { return new GoogleAnalyticsService(i0.inject(i0.NgZone)); }, token: GoogleAnalyticsService, providedIn: "root" });
    return GoogleAnalyticsService;
}(BaseService));
export { GoogleAnalyticsService };
