var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { InventoryManager } from 'aigens-ng-core';
import { MobileBasePage } from '../../core/base/mobile-base-page';
var VariationScrollDialog = /** @class */ (function (_super) {
    __extends(VariationScrollDialog, _super);
    function VariationScrollDialog(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.items = [];
        _this.selectedIndex = -1;
        _this.category = _this.getNavParams('category');
        _this.items = _this.getNavParams('items');
        _this.store = _this.getNavParams('store');
        _this.inventoryManager = injector.get(InventoryManager);
        _this.title = _this.getNavParams('title');
        _this.orderManager = _this.getNavParams('orderManager');
        if (!_this.title) {
            _this.title = _this.t.instant('pages.variation.title');
        }
        return _this;
    }
    VariationScrollDialog.prototype.ngOnInit = function () {
        console.log('ngOnInit VariationScrollDialog', this.orderManager.getOrder());
    };
    VariationScrollDialog.prototype.dismissClicked = function () {
        this.modalController.dismiss(null);
    };
    VariationScrollDialog.prototype.isSoldout = function (item) {
        var now = this.menuManager.debugTime || new Date().getTime();
        var imSoldout = this.inventoryManager.isSoldout(item);
        // const mmSoldout = !this.menuManager.isAvailableCombo(this.store, this.category, item, this.orderManager.isTakeout(), now);
        var mmSoldout = !item.combo ? false : !this.menuManager.isAvailableCombo(this.store, this.category, item, this.orderManager.isTakeout(), now);
        return imSoldout || mmSoldout;
    };
    VariationScrollDialog.prototype.getModifierString = function (item) {
        var modifiers = '+';
        var groups = this.category.groups;
        if (item.groups && item.groups.length > 0) {
            groups = item.groups;
        }
        else {
            groups = groups.slice(1);
        }
        groups = groups.filter(function (group) { return group.items && group.items.length > 0; });
        if (!groups || groups.length === 0) {
            return '';
        }
        return modifiers + groups.map(function (group) { return group.name; }).join('+');
    };
    VariationScrollDialog.prototype.itemClicked = function (i) {
        if (i === this.selectedIndex) {
            console.log('unselected item');
            this.selectedIndex = -1;
            return;
        }
        var item = this.items[i];
        console.log('selected item', item);
        if (this.isSoldout(item)) {
            return;
        }
        this.selectedIndex = i;
        this.modalController.dismiss(this.items[this.selectedIndex]);
    };
    VariationScrollDialog.prototype.okClicked = function () {
        if (this.selectedIndex < 0) {
            return;
        }
        console.log('okClicked ', this.selectedIndex);
        this.modalController.dismiss(this.items[this.selectedIndex]);
    };
    return VariationScrollDialog;
}(MobileBasePage));
export { VariationScrollDialog };
