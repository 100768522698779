import {BrowserService} from './browser.service';
import {Charge, Data, Store} from 'aigens-ng-core';
import {AQuery} from '../base/aquery';
import {BaseService} from '../base/base-service';
import {ConfigService} from './config.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

declare var Stripe: any;
declare var window: any;
declare var ApplePaySession: any;

@Injectable({providedIn: 'root'})
export class PaymentService extends BaseService {

    aq: AQuery;
    stripe: any;
    stripeKey: string;
    hasActivePayment = false;
    supported: any = {};
    googleClient: any;

    constructor(private http: HttpClient, private configService: ConfigService, private browserService: BrowserService) {
        super();
        this.aq = new AQuery(http, configService);
    }

    getGooglePaymentsClient() {
        if (!this.googleClient) {
            this.googleClient = new (<any>window).google.payments.api.PaymentsClient({environment: 'PRODUCTION'});
        }
        return this.googleClient;
    }

    preload(store: Store, callback: Function) {


        if (!store) {
            return;
        }
        if (!store.brand) {
            return;
        }
        if (!store.brand['payments']) {
            return;
        }
        const payments = store.brand['payments'];
        this.processPayments(payments, callback, store);
    }

    processPayments(payments: any[], callback: Function, store: Store) {
        const loadedUrl = {};
        payments.forEach(payment => {
            const gateway = payment['gateway'];
            const param = {};
            let url: string;
            let callbackHandler: any;


            if (gateway === 'stripe') {

                console.log('loading stripe');
                url = 'https://js.stripe.com/v3/';
                callbackHandler = () => {
                    console.log('script loaded');
                    this.prepare(store, callback, payment.key);
                };
            }

            if (gateway === 'mpgs' && payment['method'] === 'google') {

                console.log('loading stripe');
                url = 'https://pay.google.com/gp/p/js/pay.js';
                callbackHandler = () => {
                    console.log('script loaded');
                    // this.prepare(store, callback, payment.key);
                };
            }

            if (gateway === 'wirecardV2' && payment['method'] === 'creditcard') {
                param['defer'] = '';
                // paymentHost: "https://wpp-test.wirecard.com.sg/"
                // let host = "https://wpp-test.wirecard.com";
                if (payment['paymentHost']) {
                    url = payment['paymentHost'] + '/loader/paymentPage.js';
                } else {
                    url = 'https://wpp-test.wirecard.com/loader/paymentPage.js';
                }

                callbackHandler = () => {
                    callback();
                };
                console.log('loading wirecardv2');
            }

            if (gateway === 'mpgs' && (payment['method'] === 'creditcard' || payment['method'] === 'session')) {
                param['defer'] = '';

                let apiHost = payment['APIHost'];
                let version = payment['version'];
                url = (apiHost ? apiHost : 'https://ap-gateway.mastercard.com') + '/form/version/' + (version ? version : '48') + '/merchant/' + payment['merchantId'] + '/session.js';
                console.log('use mpgs host', apiHost ? apiHost : 'https://ap-gateway.mastercard.com');
                console.log('use mpgs version', version ? version : '48');
                callbackHandler = () => {
                    callback();
                };
                console.log('loading mpgs');

            }

            if (gateway === 'midTrans' && payment['method'] === 'creditcard') {
                /*   <script id="midtrans-script" type="text/javascript"
                   src="https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js"
                   data-environment="sandbox"
                   data-client-key="<INSERT YOUR CLIENT KEY HERE>"></script>*/
                param['id'] = 'midtrans-script';
                param['data-environment'] = payment['isSandbox'] ? 'sandbox' : 'production';
                param['data-client-key'] = payment['clientKey'];
                url = 'https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js';
                callbackHandler = () => {
                    callback();
                };

            }

            if (url && !loadedUrl[url]) {

                this.configService.loadScript(url, callbackHandler, param);
                loadedUrl[url] = true;
            }

        });
    }

    courtPreload(p: any, callback: Function, store: any) {

        const payments = p;
        console.log('courtPreload payment', payments);
        this.processPayments(payments, callback, store);
    }

    getStripe() {
        return this.stripe;
    }


    prepare(store: Store, callback: Function, stripeKey?: string) {

        if (!store.pos) {
            return;
        }

        if (this.stripeKey) {
            this.checkPaymentMethodAvailable();
            return;
        }

        this.stripeKey = this.configService.get('stripe');
        if (stripeKey) {
            this.stripeKey = stripeKey;
        }

        console.log('stripe key...', this.stripeKey, stripeKey);

        const stripe = Stripe(this.stripeKey);

        const pos = store.pos;

        console.log('payments?', pos);

        this.stripe = stripe;

        this.checkPaymentMethodAvailable();


    }

    checkPaymentMethodAvailable() {
        const stripe = this.stripe;
        if (!stripe) {
            this.hasActivePayment = true;
            return;
        }

        const paymentRequest = stripe.paymentRequest({
            country: 'HK',
            currency: 'hkd',
            total: {
                label: 'Aigens',
                amount: 400
            }
        });
        paymentRequest.canMakePayment().then((result) => {
            console.log('can make payment', result);

            if (result) {
                this.hasActivePayment = true;
            } else {
                this.hasActivePayment = false;
            }
        });
    }

    listenToken(payName: string, store: Store, grandTotal: number, callback: Function) {

        let amount;
        if (store.currency === 'JPY') {
            amount = Math.floor(grandTotal * 1);
        } else {
            amount = Math.floor(grandTotal * 100);
        }
        const stripe = this.getStripe();

        const paymentRequest = stripe.paymentRequest({
            country: store.country.toUpperCase(),
            currency: store.currency.toLowerCase(),
            total: {
                label: payName,
                amount: amount
            }
        });

        this.listen(paymentRequest, callback);

        return paymentRequest;
    }

    payStripeCharge(token: string, charge: Charge): Observable<Charge> {

        const url = '/api/v1/pay/charge.json';

        const params = {};
        params['type'] = charge.type;
        params['token'] = token;
        params['amount'] = charge.amount;
        params['currency'] = charge.currency;
        params['groupId'] = '100';
        params['email'] = charge.email;
        params['subtype'] = charge.subtype;
        params['method'] = charge.method;

        if (charge.payeeId) {
            params['payeeId'] = charge.payeeId;
        }

        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;

        return aq.getJson().pipe(map(jo => Data.toData(Charge, jo['data'])));


    }

    canApplePay(): boolean {

        const aps = window.ApplePaySession;

        console.log('apple pay session', aps);

        if (aps) {
            if (aps.canMakePaymentsWithActiveCard) {
                return this.hasActivePayment;
            }
        }

        return false;
    }

    canAndroidPay(): boolean {

        if (this.browserService.ios) {
            return false;
        }

        if (window.PaymentRequest) {
            return this.hasActivePayment;
        }

        return false;
    }

    postApplePaySession(appleServerUrl: string, domain: string): Observable<any> {
        const url = this.configService.get('applePay'); // "https://pay-dot-aigensstoreapp.appspot.com/api/v1/pay/applesession.json";

        const body = {url: appleServerUrl, domain: domain};
        console.log(body);

        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;

        return aq.getJson().pipe(map(jo => jo));


    }

    private listen(paymentRequest, callback: Function) {

        const stripe = this.getStripe();

        paymentRequest.on('token', (ev) => {

            console.log('token!!', ev);

            callback(ev);


        });

        const elements = stripe.elements();
        const prButton = elements.create('paymentRequestButton', {
            paymentRequest: paymentRequest,
        });

        // Check the availability of the Payment Request API first.
        paymentRequest.canMakePayment().then((result) => {

            console.log('can make payment?', result);

            if (result) {
                this.supported = result;
                console.log('can make payment', result);
                prButton.mount('#payment-request-button');
                console.log('prButton', prButton);
            } else {
                // document.getElementById('payment-request-button').style.display = 'none';
                console.log('cannot pay');
            }
        });
    }


}
