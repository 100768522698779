<!--
  Generated template for the Passcode page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->



<ion-content padding (click)="$event.stopPropagation()">
        <div class="custom-dialog-wrapper">
      
          <div class="custom-dialog" (click)="$event.stopPropagation()">
            <div class="content-container">
              <div class="text-box">
                <!-- <p>{{"pages.splash.enterPasscode" | translate}}</p> -->
                <!-- <img class="k-logo-img" src="./assets/images/hotelDel-01.svg"> -->
        
                <!-- <div *ngIf="spotDesc" [innerHTML]="spotDesc|safeHtml"></div>
                        
                        <p *ngIf="!spotDesc">{{"pages.splash.enterPasscode-hkbh1" | translate}}<span class="strong-text">{{"pages.splash.enterPasscode-hkbh2" | translate}}</span></p>
                        <p *ngIf="!spotDesc" class="strong-text">{{"pages.splash.enterPasscode-hkbh3" | translate}}</p> -->
        
                <p>When do you want your meal?</p>
              </div>
              <div class="input-box">
        
                <div (click)="confirmClicked('now')" class="btn-now">
                  <p>Now</p>
                </div>
        
                <div class="text-or">
                  <span class="deco-line"></span>
                  <p>OR</p>
                  <span class="deco-line"></span>
                </div>
        
                <div class="picker-box" (click)="$event.stopPropagation()">
                  <!-- <p class="c-ali">Order for later</p> -->
        
                  <div class="picker-date">
                    <div class="picker-inner-wrapper" [ngClass]="{'picked': chooseOtp == todayOpt}">
                      <div no-lines (click)="pickDate('0')">
                        <!-- <ion-label>{{todayOptDisplay}} - Choose</ion-label> -->
                        <p class="text-month">{{dateDisplayMap['0month']}}</p>
                        <p class="text-date">{{dateDisplayMap['0day']}}</p>
                        <span class="picked-deco-line"></span>
                      </div>
                  
                    </div>
                  
                    <div class="picker-inner-wrapper" [ngClass]="{'picked': chooseOtp == tmrOtp}">
                      <div no-lines (click)="pickDate('1')">
                        <!-- <ion-label>{{todayOptDisplay}} - Choose</ion-label> -->
                        <p class="text-month">{{dateDisplayMap['1month']}}</p>
                        <p class="text-date">{{dateDisplayMap['1day']}}</p>
                        <span class="picked-deco-line"></span>
                      </div>
                  
                    </div>
                  
                    <div class="picker-inner-wrapper" [ngClass]="{'picked': chooseOtp == dayAfterTmrOpt}">
                      <div no-lines (click)="pickDate('2')">
                        <!-- <ion-label>{{todayOptDisplay}} - Choose</ion-label> -->
                        <p class="text-month">{{dateDisplayMap['2month']}}</p>
                        <p class="text-date">{{dateDisplayMap['2day']}}</p>
                        <span class="picked-deco-line"></span>
                      </div>
                  
                    </div>
                  </div>
        
                  <div class="picker-time" *ngIf="!isTodayPicker">
                    <p class="picker-text-time">Time</p>
                    <ion-datetime displayFormat="HH:mm" pickerFormat="HH mm" [(ngModel)]="myDate"
                    (ionChange)="updateMyDate($event)"></ion-datetime>
                    <ion-icon name="md-arrow-dropdown"></ion-icon>
                  </div>
        
                  <div class="picker-time" *ngIf="isTodayPicker">
                    <p class="picker-text-time">Time</p>
                    <ion-datetime displayFormat="HH:mm" pickerFormat="HH mm" [(ngModel)]="myDate"
                    (ionChange)="updateMyDate($event)" min="{{nowMin}}"></ion-datetime>
                    <ion-icon name="md-arrow-dropdown"></ion-icon>
                  </div>
        
        
        
        
                  <!-- <div class="picker-inner-wrapper" *ngIf="!isTodayPicker">
        
                    <div no-lines>
                      
                      <ion-datetime displayFormat="HH:mm" pickerFormat="HH mm" [(ngModel)]="myDate"
                        (ionChange)="updateMyDate($event)"></ion-datetime>
                    </div>
        
                  </div> -->
        
        
                </div>
        
              </div>
              <div (click)="confirmClicked(null);$event.stopPropagation()" class="btn-box">
                <p> Confirm </p>
              </div>
              <!-- <button class="close-btn" ion-button icon-only (click)="$event.stopPropagation();dismissClicked()">
                            <ion-icon name="ios-close" color="dark"></ion-icon>
                        </button> -->
      
            </div>
      
      
           
          </div>
          <!-- <div class="btn-close">
          <ion-icon name="ios-close-circle-outline"></ion-icon>
          </div> -->
      
      
        </div>
      
      
      </ion-content>
