import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {NavigationService} from '../services/navigation.service';
import {NavController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ModeGuard implements CanActivate {
    constructor(public router: Router, public navigationService: NavigationService, public navController: NavController) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const mode = next.params['mode'];
        if (mode === 'takeaway' || mode === 'pickup' || mode === 'dinein' || mode === 'delivery' || mode === 'preorder' || mode === 'prekiosk') {
            return true;
        } else {
            console.warn('invalid mode', mode);
            this.navController.setDirection('root');
            this.router.navigate(['/'], {replaceUrl: true});
        }
    }
}
