import {MobileBasePage} from '../../core/base/mobile-base-page';
import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {Storage} from '@ionic/storage';

/*
  Generated class for the TermsConditions page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/

declare var window;

@Component({
    selector: 'page-external-web',
    templateUrl: 'external-web-page.html',
    styleUrls: ['external-web-page.scss']
})
export class ExternalWebPage extends MobileBasePage implements OnInit {
    @ViewChild('secure') secureFrame;
    body = '';
    url: string;

    // constructor(public viewCtrl: ViewController, injector: Injector, params: NavParams,public startupService: StartupService
    constructor(injector: Injector, public storage: Storage) {
        super(injector);
        console.log('ExternalWebPage');
        this.body = this.getNavParams('body');
        this.url = this.getNavParams('url');

    }

    ngOnInit() {
        window.closeFrame = (result) => {
            console.log('external web result', result);
            this.modalController.dismiss(result);
        };
        if (this.body) {
            this.secureFrame.nativeElement.contentWindow.document.write(this.body);
            this.secureFrame.nativeElement.contentWindow.document.close();
        } else if (this.url) {
            this.secureFrame.nativeElement.src = this.url;
        }
    }

    dismiss(agree?: boolean) {
        //this.secureFrame.nativeElement.src = window.location.origin + "/redirect?membercardid=1234";
        console.log('dismiss');
        if (agree) {
            this.modalController.dismiss();

        } else {
            this.modalController.dismiss();

        }
    }


}
