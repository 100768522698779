import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'sortItem',
})

export class SortItemPipe implements PipeTransform {

  constructor() { }
/*   transform(arr: any = [], has: any): any {
    arr.sort((a1,a2) => {
      return has(a1) ? -1 : 1
    })
    return arr
  } */

  transform(arr : any = [], defaultId: any): any {
    if (!defaultId) {
      return arr;
    }
    else {
      arr.sort((item1, item2) => {
          return item1.id === defaultId? -1 : 1;
      })
      return arr;
    }
  }
}
