import { Router } from '@angular/router';
import { NavigationService } from '../services/navigation.service';
import { NavController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/navigation.service";
import * as i3 from "@ionic/angular";
var ModeGuard = /** @class */ (function () {
    function ModeGuard(router, navigationService, navController) {
        this.router = router;
        this.navigationService = navigationService;
        this.navController = navController;
    }
    ModeGuard.prototype.canActivate = function (next, state) {
        var mode = next.params['mode'];
        if (mode === 'takeaway' || mode === 'pickup' || mode === 'dinein' || mode === 'delivery' || mode === 'preorder' || mode === 'prekiosk') {
            return true;
        }
        else {
            console.warn('invalid mode', mode);
            this.navController.setDirection('root');
            this.router.navigate(['/'], { replaceUrl: true });
        }
    };
    ModeGuard.ngInjectableDef = i0.defineInjectable({ factory: function ModeGuard_Factory() { return new ModeGuard(i0.inject(i1.Router), i0.inject(i2.NavigationService), i0.inject(i3.NavController)); }, token: ModeGuard, providedIn: "root" });
    return ModeGuard;
}());
export { ModeGuard };
