import { Item, ItemGroup, ModifierBuilder, OrderItem, OrderItemBuilder, OrderManager, Store } from 'aigens-ng-core';
import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { SideBarModifierDialog } from '../../../dialogs/sideBarModifier/sideBarModifier';
import { EnlargePage } from './enlargePage/enlargePage';
import { ModifierItemSelectPageModal } from '../modifier-item-select-modal/modifier-item-select-modal';
import { NavParams } from '@ionic/angular';
import { popDrownAnimation, popUpAnimation } from 'src/app/shared/animations/leaveAnimation';
import { BaseComboPage } from '../../../core/base/base-combo-page';
import { RouteTable } from 'src/app/core/route-table';

@Component({
    selector: 'combo-page',
    templateUrl: './combo-page.html',
    styleUrls: ['./combo-page.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComboPage extends BaseComboPage implements OnInit {

    defaultModifierPage = SideBarModifierDialog;


    originalGroups: ItemGroup[]; // 给重新选择variations时, 提供上一板传来的原始groups.使其可以刷新数据

    items: Item;

    tagsImage = {};
    // subgroup expand status


    autoPickupGroups: ItemGroup[] = []; // html暂时未show
    nonAutoPickupGroups: ItemGroup[] = [];
    requiredModifierMap: any = {};
    modifiableMap: any = {};
    totalQty: any;
    isGroupExpand: any = {};

    constructor(injector: Injector, private cd: ChangeDetectorRef, public modalNavParams: NavParams) {
        super(injector);

        this.defaultModifierPage = SideBarModifierDialog;


        console.log('toggle set menu page construction', typeof this.defaultModifierPage);
    }

    // to fix the bug of noneed (this.groups will change after the constructor() implement)
    initPage() {
        console.log('ComboPage set menu page construction');
        this.store = this.modalNavParams.get('store');
        this.edit = this.modalNavParams.get('edit');
        this.items = this.modalNavParams.get('items');
        this.originalGroups = this.modalNavParams.get('groups');

        if (this.store) {
            this.preselectItem = this.settings.preselectItem;
            this.isAutoPickup = this.settings.autoPickup;
        }

        if (this.multOrderManager.enableMutlOrder) {
            this.settingMultiOrderManager();
        } else if (this.orderManager.isEmpty()) {
            this.orderManager.createOrder(this.store.id, null, null, null, this.store);
        }
        this.orderManager.settingOrderType(this.orderManager.mode);

        let groups: ItemGroup[];
        let mainItem: Item;
        this.builder = new OrderItemBuilder(this.store);

        if (!this.edit) {
            this.category = this.modalNavParams.get('category');

            // category.groups
            groups = this.modalNavParams.get('groups');
            mainItem = this.modalNavParams.get('item');

        } else {
            const origins = this.builder.findOrigins(this.edit);
            this.category = origins.category;
            groups = origins.groups;
            mainItem = origins.item;
        }

        this.builder.category = this.category;
        if (mainItem.groups && mainItem.groups.length > 0) {
            this.mainGroup = groups[0]; // buger页的所有数据
            groups = mainItem.groups; // 当前特定bugger的所有items Groups
            this.mainItem = mainItem;
            this.mainItem.quantity = 1;
        } else {

            // var choices = groups[0].choices;
            const min = groups[0].min;
            const max = groups[0].max;
            const choices = groups[0].choices;
            if ((max === 1 && min === 1) || choices === 1) {
                this.mainGroup = groups[0];
                groups = groups.slice(1);
                this.mainItem = mainItem;
                this.mainItem.quantity = 1;
            }
        }

        if (this.store) {
            this.handleOrderManager();
        }
        // DEEP CLONE!
        groups = groups && groups.filter(group => {
            return this.menuManager.isAvailableGroup(this.store, this.category, group, this.orderManager.order.takeout, Date.now(), false);
        });
        this.groups = groups;
        if (!this.edit) {
            this.builder.setItem(this.mainGroup, this.mainItem, 0, this.mainItem.quantity, false, false);
        } else {
            // this.builder.transferSubitems(this.edit, this.groups);

            // include mainitem
            this.builder.transferAllItems(this.edit, this.groups);

            // build all no need subitem group

            for (let j = 0; j < this.groups.length; j++) {
                let matched = false;

                for (let i = 0; i < this.edit.groups.length; i++) {
                    // pass main item
                    if (i !== 0) {
                        if (this.groups[j].id === this.edit.groups[i].id) {
                            // console.log("matched and put", this.groups[j].name);
                            matched = true;
                            break;
                        }
                    }
                }

                if (!matched) {
                    // console.log('i am putting null clickedNoNeed: ', this.groups[j].name);
                    this.noNeedConfirm[this.groups[j].id] = true;
                    this.builder.clearItems(j + 1);
                    this.builder.build();
                }
            }
        }

        const orderItem: OrderItem = this.builder.build();

        this.modifierBuilder = new ModifierBuilder(this.store, orderItem);
        if (this.edit) {
            this.modifierBuilder.transfer(this.edit);
            this.modifierBuilder.build();
        }
        this.flatten();
        this.initExpand();
        this.total = this.mainItem.price;

        const badges = this.store.menu.badges;
        if (badges) {
            badges.forEach(badge => {
                const tagImg = badge.getImage('default', 400, true);
                if (tagImg) {
                    this.tagsImage[badge.tag] = tagImg;

                }
            });

        }

        this.updateSummaryDisplay();

    }

    updateSummaryDisplay() {
        console.log('builder!', this.builder.groups);
        let groups = this.builder.groups;

    }
    expand(groupId) {
        if (this.isGroupExpand[groupId]) {
            this.isGroupExpand[groupId] = false;
        } else {
            this.isGroupExpand[groupId] = true;
        }
    }

    settingMultiOrderManager() {
        let storeId = this.store.id;
        if (storeId) return;
        if (this.multOrderManager.getOrderManager(storeId)) {
            this.orderManager = this.multOrderManager.getOrderManager(storeId);
        } else {
            const orderManager = new OrderManager();
            orderManager.mode = this.orderManager.mode;
            orderManager.createOrder(storeId, null, null, null, this.store);
            this.multOrderManager.setOrderManagerMap(orderManager, storeId);
            this.orderManager = this.multOrderManager.getOrderManager(storeId);

        }
    }

    dismissClicked() {
        this.modalController.dismiss();
    }

    async modifierClicked() {
        if (this.modifierShown)
            return;
        const ois = this.builder.build();
        console.log('modifierClicked');

        let params = { store: this.store, orderitem: ois, edit: true };
        // window.history.pushState({}, 'Order.Place', window.location.href);
        const popup = await this.modalController.create({
            component: ModifierItemSelectPageModal,
            componentProps: params,
            cssClass: 'modal-largescreen modify-page ',
            showBackdrop: false,
            animated: true,
            enterAnimation: popUpAnimation,
            leaveAnimation: popDrownAnimation
        });
        popup.onDidDismiss().then(res => {
            this.modifierShown = false;
            if (res && res.data) {
                this.checkAutoPickupModifierCompleted();
                this.builder.build(); // update the builder.items
                this.cd.detectChanges();
            }
        });
        popup.present().then(data => {
            this.modifierShown = true;
        });
    }


    ngOnInit() {
        super.ngOnInit();
        this.initPage();
        console.log('on init', this.groups);
        this.handleModifier();
        this.checkAutoPickupHasModifier();
        this.checkAutoPickupModifierCompleted();
        this.cd.detectChanges();
        this.totalQty = 1;

    }


    // TODO: merge with the function above
    checkAutoPickupModifierCompleted(): any {
        console.log('checking isAutoPickupmodifiercompleted');
        let completed = true;

        console.log('this.builder.groups', this.builder.groups);
        console.log('this.mainItem', this.mainItem);
        // fist check if main item is selected and check itself has modiifer or not
        if (this.builder.groups && this.builder.groups[0] && this.mainItem) {
            if (this.builder.groups[0].items[0] && this.builder.groups[0].items[0].id === this.mainItem.id) {

                for (const mgroup of this.mainItem.mgroups) {
                    if (mgroup.min > 0) {

                        const mgroups: ItemGroup[] = this.builder.groups[0].items[0].mgroups;

                        if (mgroups) {
                            for (let i = 0; i < mgroups.length; i++) {
                                let quantities = 0;
                                for (const tmpItem of mgroups[i].items) {
                                    quantities += 1 * tmpItem.quantity;
                                }

                                if (mgroups[i].min > 0) {
                                    completed = quantities >= mgroups[i].min;
                                    if (!completed) {
                                        return;
                                    }
                                }
                            }
                        } else {
                            completed = false;
                        }
                    }
                }
            }
        }

        for (let i = 0; i < this.mainItem.groups.length; i++) {
            console.log('i', i);
            // check autopickup group with mainItem to see if it needs modifier
            if (this.isAutoPickGroup(this.mainItem.groups[i])) {
                console.log('mainitem.group', this.mainItem.groups[i]);
                for (const item of this.mainItem.groups[i].items) {
                    if (item.mgroups.length > 0) {
                        console.log('this.builder.groups', this.builder.groups);
                        console.log('this.builder.groups[i]', this.builder.groups[i + 1]);
                        let isItemMgroupMinGreaterThanZero = false;

                        if (!this.builder.groups[i + 1] || !this.builder.groups[i + 1].items || !this.builder.groups[i + 1].items[0] || !this.builder.groups[i + 1].items[0].mgroups) {
                            for (const itemMgroup of item.mgroups) {
                                if (itemMgroup.min > 0) {
                                    isItemMgroupMinGreaterThanZero = true;
                                    break;
                                }
                            }
                            if (isItemMgroupMinGreaterThanZero) {
                                completed = false;
                                return;
                            } else {
                                if (completed) {
                                    this.isAutoPickupModifierCompleted = true;
                                    return;
                                }
                            }
                        }

                        for (let j = 0; j < this.builder.groups[i + 1].items[0].mgroups.length; j++) {
                            let quantities = 0;
                            for (const tmpItem of this.builder.groups[i + 1].items[0].mgroups[j].items) {
                                quantities += 1 * tmpItem.quantity;
                            }

                            if (item.mgroups[0].min > 0) {
                                completed = quantities >= item.mgroups[0].min;

                                if (!completed) {
                                    return;
                                }
                            }
                        }
                    } else {
                        completed = true;
                    }
                }
            }
        }

        if (completed) {
            this.isAutoPickupModifierCompleted = true;
        }

    }

    async showImage(item) {
        let defaultImage = this.getDefaultImage(true, this.store.brand, 500);
        let imgUrl = item.getImage('default', 500, true) || defaultImage;
        console.log('enlarge img', this.modalController);

        // img modal
        let page = EnlargePage;
        const modal = await this.modalController.create({
            component: page,
            componentProps: { imgUrl },
            animated: true,
            showBackdrop: true,
            backdropDismiss: true,
            cssClass: 'modal-largescreen enlarge-modal'
        });
        modal.onDidDismiss().then((confirm) => {
            console.log('close enlarge modal');
        });
        modal.present();
    }


    initExpand() {
        for (let group of this.nonAutoPickupGroups) {
            this.isGroupExpand[group.id] = true;
            return;
        }
    }
    // noNeed
    clickedNoNeed(group: ItemGroup, index: any) {
        // if (this.noNeedConfirm[group.id]) {
        //     return;
        // }
        const groupIndex = this.groups.indexOf(group) + 1;

        console.log('group clickedNoNeed: ', groupIndex, group);
        console.log('this.builder', this.builder.groups[groupIndex]);

        let quantities = null;
        if (this.builder.groups[groupIndex] && this.builder.groups[groupIndex].items && this.builder.groups[groupIndex].items.length) {
            quantities = 0;
            // quantities = quantities + this.builder.getQuantity
            for (const item of this.builder.groups[groupIndex].items) {
                quantities = quantities + this.builder.getQuantityInGroup(groupIndex, item.id);
            }
            console.log('check quantities', quantities);
        }

        this.noNeedConfirm[group.id] = true;
        this.builder.clearItems(groupIndex);
        this.builder.build();

        // for checking next group
        // TODO: go to next incomplete group
        const itemGroup = this.groups[index + 1];
        console.log('checking next itemGrp', this.groups, index + 1);
        this.updateSelectedModifier();

        this.isGroupExpand[group.id] = false;
        if (itemGroup)
            this.isGroupExpand[itemGroup.id] = true;
        this.cd.detectChanges();
        // this.next();


    }

    totalQtyAddClicked() {
        this.totalQty += 1;
    }

    totalQtyMinusClicked() {
        if (this.totalQty > 1) {
            this.totalQty -= 1;
        }
    }

    addToCartClicked() {
        this.googleAnalyticEventTrack('add to cart');
        console.log(this.groups);
        if (!this.isCompleted() || !this.isAutoPickupModifierCompleted) {
            // let checked = false;
            this.missing = true;
            // TODO: scroll to the first incomplete group
            this.showNotCompletedAlert();
            return;
        }

        if (this.totalQty < 1) {
            this.showQtyAlert();
            return;
        }

        if (this.groups && this.groups.length > 0) {
            this.missing = false;

            const oi: OrderItem = this.builder.build();
            oi.combo = true;

            let orderManager;
            if (this.multOrderManager.enableMutlOrder) {
                orderManager = this.multOrderManager.getOrderManager(this.store.id);
            } else {
                orderManager = this.orderManager;
            }

            // const handler = this.modalNavParams.get('handler');

            const handler = this.modalNavParams.get('handler');

            if (!handler) {
                if (this.edit) {
                    // this.orderService.replaceOrderItem(this.edit, this.category, igs);
                    if (this.totalQty === 1) {
                        orderManager.replaceOrderItem(this.edit, oi);
                    } else {
                        orderManager.replaceOrderItem(this.edit, oi);
                        for (let i = 0; i < this.totalQty - 1; i++) {
                            const oi_: OrderItem = this.builder.build();
                            oi_.combo = true;
                            orderManager.addOrderItem(oi);
                        }
                    }
                } else {
                    // this.orderService.addSet(this.category, igs);
                    if (this.totalQty > 0) {
                        for (let i = 0; i < this.totalQty; i++) {
                            const oi_: OrderItem = this.builder.build();
                            oi_.combo = true;
                            orderManager.addOrderItem(oi_);
                        }
                    }
                }

            }

            // this.showToast(this.t.instant('global.item-added'), 1000, 'bottom', 'add-item-toast');
            // this.dismissClicked();
            if (orderManager.store.menu && orderManager.store.menu.quickCheckout || handler) {
                this.modalController.dismiss().then(() => {
                    // this.pushByUrl(this.multOrderManager.enableMutlOrder ? RouteTable['MultiOrderCartPage'] : RouteTable['CartPage'], {}, {replaceUrl: true}).then(
                    this.pushByUrl(this.multOrderManager.enableMutlOrder ? RouteTable['MultiOrderCartPage'] : RouteTable['CartScrollPage'], {}).then(
                        () => {
                            // find index of home page, and remove from home page
                            let page: any;
                            const catLayout = this.settings.catLayout;

                            if (handler) {
                                handler(oi);
                            }

                        }
                    );
                });
            } else {
                //     if (!this.edit) {
                //         this.showToast(this.t.instant('global.item-added'), 1000, 'bottom', 'add-item-toast');
                //         // this.showToast(this.t.instant('global.item-added'));
                //     }

                this.modalController.dismiss();

            }
        } else {
            if (this.isRequiredModifier(this.mainItem) || (this.isModifiable(this.mainItem) && !this.settings.quickSingleItem)) {
                // the foodItem special request select

                // tslint:disable-next-line:no-var-keyword
                var groups = this.category.groups,
                    groups = groups && groups.filter(group => {
                        return this.menuManager.isAvailableGroup(this.store, this.category, group, this.orderManager.order.takeout, Date.now(), false);
                    });

                //   let oi = this.orderManager.createOrderItem(cate, cate.groups[0], item);
                let oi = this.addItem(this.mainItem);
                this.add(this.mainItem, groups[0]);
                // this.showToast(this.t.instant('global.item-added'), 1000, 'bottom', 'add-item-toast');
                // this.pushByName('ModifierItemSelectPage', { store: this.store, orderitem: oi, callback: cb }, {relativeTo: this.route, skipLocationChange: false});
                this.backButtonClick();
            } else {
                if (this.totalQty > 0) {
                    for (let i = 0; i < this.totalQty; i++) {
                        this.addItem(this.mainItem);
                    }
                }
                // this.showToast(this.t.instant('global.item-added'), 1000, 'bottom', 'add-item-toast');
                this.backButtonClick();
            }
        }


    }


    addItem(item: Item): OrderItem {

        const category = this.category;
        // this.ord(erService.addSingleItem(this.category, this.groups[0], item);
        console.log('om order', this.orderManager.getOrder());
        const oi = this.orderManager.addSingleItem(category, category.groups[0], item, false);
        this.orderManager.getQuantity(item);
        // TODO: implement virtualscroll
        // this.vscroll.writeUpdate(true);
        return oi;

    }

    // new thing
    flatten() {
        let i = 0;
        // let y = 0;
        console.log(this.groups);
        this.autoPickupGroups = [];
        this.nonAutoPickupGroups = [];
        this.itemMap = {};
        for (const group of this.groups) {
            const items = [];
            // get all available items of the given group
            const mainItems = this.menuManager.getAvailableItems(this.store, this.category, group, this.orderManager.isTakeout());
            group.items = mainItems;
            // let isSet = false;
            // init status for title group
            this.isTitleGroupExpand[group.id] = {};
            // make a copy of itemGroupMap with some special value?
            let modifiedItems: Item[] = [];
            const isAutoPickupGroup = this.isAutoPickup && this.isAutoPickGroup(group, mainItems);
            if (isAutoPickupGroup) {
                this.autoPickupGroups.push(group);
            } else {
                this.nonAutoPickupGroups.push(group);
            }
            if (mainItems && mainItems.length > 0) {
                mainItems.forEach((item, index) => {
                    // item['groupIndex'] = i + 1;
                    // put all itemGroupMap in itemMap
                    this.itemMap[item.id] = item;

                    // if not edit mode and should do auto pickup
                    if (isAutoPickupGroup && !this.edit) {
                        console.log('auto pick group', group);
                        this.addClicked(item, group, false, true);
                        // isSet = true;
                    }

                });

                // handle drinks
                modifiedItems = this.groupingTitle(group, mainItems);
                // if (this.edit) {
                //         if (!(group.min === group.max && mainItems.length === group.min && this.isAutoPickup) || !this.isAutoPickup) {
                //                 group['isAutopickUp'] = true; // show item
                //         } else {
                //         }
                // } else {
                // if (!this.isGroupCompleted(group) || !this.isAutoPickup) {
                // } else if (group.optional === false) {
                //         // 因為auto pickup 出錯，特定條件下會把item group一些 un-available itemGroupMap 都pickup 起來,应该忽略optional === true 的 item group
                //
                // }
                // }
                items.push(...modifiedItems);
            }

            if (this.preselectItem && !this.edit) {

                let preselectItem: Item[] = [];
                let startIndex = 0;
                let titleIndex = 0;
                let tmpItems = modifiedItems;

                for (let i = 0; i < group.min + 1; i++) {
                    // handle for group.min is 0
                    if ((group.min === 0 && i === 0) || (group.min > 0 && i !== group.min)) {
                        preselectItem[i] = group.defaultId && this.itemMap[group.defaultId] && i === 0 ? this.itemMap[group.defaultId] : null; // have default id
                        if (preselectItem[0] && group.repeat) {

                            break;
                        }

                        if (!preselectItem[i]) {
                            if (!tmpItems[startIndex]) {
                                tmpItems = this.sameTitleItems[this.titles[group.id][titleIndex]];
                                startIndex = 0;
                                titleIndex++;
                            }

                            if (tmpItems[startIndex] && group.defaultId === tmpItems[startIndex].id) {
                                startIndex++;
                            }
                            preselectItem[i] = tmpItems[startIndex];
                            startIndex = startIndex + 1;
                        }


                        this.addOrderItemMap(group, preselectItem[i]);
                    }

                }

                if (preselectItem) {
                    // this.addClicked(preselectItem, false,true);

                    for (let i = 0; i <= group.min; i++) {
                        if ((group.min === 0 && i === 0) || (group.min > 0 && i !== group.min)) {
                            if (group.repeat) {
                                if (this.getQuantity(preselectItem[0], group) < group.max) {
                                    // 获取当前的预选 item 的数量 ,小于可选的最大值才可以添加, 否则有时(数量等于最大值并最大值为1)时执行之后addclicked会清掉之前autoPickUp选中的item
                                    this.addClicked(preselectItem[0], group, false, true);
                                    this.addOrderItemMap(group, preselectItem[0]);
                                }

                            } else {
                                if (this.getQuantity(preselectItem[i], group) < group.max) {
                                    // 获取当前的预选 item 的数量 ,小于可选的最大值才可以添加, 否则有时(数量等于最大值并最大值为1)时执行之后addclicked会清掉之前autoPickUp选中的item
                                    this.addClicked(preselectItem[i], group, false, true);
                                    this.addOrderItemMap(group, preselectItem[i]);
                                }
                            }
                        }
                    }
                }
            }

            this.itemGroupMap[group.id] = items;
            if (this.sameTitleItems[group.id]) {
                for (const key in this.sameTitleItems[group.id]) {
                    const index = this.titles[group.id].indexOf(key);
                    this.isTitleGroupExpand[group.id][key] = false;
                    // this.titleGroupClicked(key, index, group.id);
                }
                // for (const key in this.isTitleGroupExpand[group.id]) {
                //     console.log('loop title expand?', this.isTitleGroupExpand);
                //     if (this.isTitleGroupExpand[group.id][key]) {
                //         const index = this.titles[group.id].indexOf(key);
                //         this.hasItemTitleGroup[group.id] = {title: key, index: index};
                //         this.titleGroupClicked(key, index, group.id);
                //     }
                // }
                // init sub item groups

            }
            i++;
        }
        console.log(this.itemGroupMap);

    }



    add(item: Item, group: ItemGroup, isPreselect: boolean = false) {
        let oi;
        let groupIndex = this.getGroupIndex(group);
        if (groupIndex === -1) {
            groupIndex = this.groups.findIndex(g => {
                // TODO: is compared by id safe?
                return (g !== null && g !== undefined && g.id === group.id);
            }) + 1;
        }

        const isOptional = group.min === 0;
        if (this.getGroupMode(group) === 'single') {
            const max = group.max;
            let able = true;
            if (max >= 1) {
                const count: number = this.totalQuantity(groupIndex);

                if (count >= max && max !== 1) {
                    able = false;
                }
            }

            if (max === 1 && !this.has(item, group)) {

                this.builder.clearItems(groupIndex);

            }

            if (able && !this.has(item, group)) {

                this.builder.plusItem(group, item, groupIndex);
                oi = this.builder.build();
            } else if (this.has(item, group)) {
                this.discard(item, group);
            }

            if (!isPreselect) {
                if (this.isGroupCompleted(group)) {
                    let groupNextIndex = this.nonAutoPickupGroups.indexOf(group) + 1;
                    this.isGroupExpand[group.id] = false;
                    if (this.nonAutoPickupGroups[groupNextIndex]) { this.isGroupExpand[this.nonAutoPickupGroups[groupNextIndex].id] = true; }

                    this.handlingModifier(oi, group, this.itemMap, () => {
                    }, () => {
                        this.minusClicked(item, group);
                    });
                } else if (isOptional) {
                    const realItem = this.itemMap[item.id];
                    let groupNextIndex = this.nonAutoPickupGroups.indexOf(group) + 1;
                    this.isGroupExpand[group.id] = false;
                    if (this.nonAutoPickupGroups[groupNextIndex]) { this.isGroupExpand[this.nonAutoPickupGroups[groupNextIndex].id] = true; }

                    if (realItem.mgroups && realItem.mgroups.length > 0) {
                        this.handlingModifier(oi, group, this.itemMap, () => {
                        }, () => {
                            this.minusClicked(item, group);
                        }, true);

                    }
                }

            }

        } else if (this.getGroupMode(group) === 'quantity') {
            const max: number = group.max;
            if (max <= this.totalQuantity(groupIndex)) {
                console.log('max reached');
                return;
            }

            this.builder.plusItem(group, item, groupIndex);
            oi = this.builder.build();
            if (!isPreselect) {
                if (this.isGroupCompleted(group)) {
                    let groupNextIndex = this.nonAutoPickupGroups.indexOf(group) + 1;
                    this.isGroupExpand[group.id] = false;
                    if (this.nonAutoPickupGroups[groupNextIndex]) { this.isGroupExpand[this.nonAutoPickupGroups[groupNextIndex].id] = true; }

                    this.handlingModifier(oi, group, this.itemMap, () => {
                    }, () => {
                        const tmpItems: Item[] = [];
                        for (const item of oi.groups[groupIndex].items) {
                            for (let i = 0; i < item.quantity; i++) {
                                tmpItems.push(this.itemMap[item.id]);

                            }

                        }
                        for (const item of tmpItems) {
                            this.minusClicked(item, group);

                        }
                    });

                } else if (isOptional) {
                    let groupNextIndex = this.nonAutoPickupGroups.indexOf(group) + 1;
                    this.isGroupExpand[group.id] = false;
                    if (this.nonAutoPickupGroups[groupNextIndex]) { this.isGroupExpand[this.nonAutoPickupGroups[groupNextIndex].id] = true; }

                    const realItem = this.itemMap[item.id];
                    if (realItem.mgroups && realItem.mgroups.length > 0) {
                        this.handlingModifier(oi, group, this.itemMap, () => {
                        }, () => {
                            this.minusClicked(item, group);
                        }, true);

                    }
                }

            }
        }

    }

    titleGroupClicked(title: string, index: number, groupId: string, overwrite?: 1 | -1) {
        // const childNode = document.getElementById('child-' + groupId + '-' + index);
        // if (this.isTitleGroupExpand[groupId]) {
        //     if (overwrite) {
        //         this.isTitleGroupExpand[groupId][title] = overwrite === 1;
        //     } else {
        //         this.isTitleGroupExpand[groupId][title] = !this.isTitleGroupExpand[groupId][title];
        //     }
        //     if (childNode) {
        //         childNode.style.maxHeight = this.isTitleGroupExpand[groupId][title] ? childNode.scrollHeight + 'px' : '0px';
        //     }
        // }
    }

    // item-selected-tap-tips
    getModifierItemDisplay(imap: any, item: Item): string {

        if (item.quantity > 0) {

            let name = item.name;
            if (item.quantity > 1 && item.marker !== 'no-receipt') {
                name = name + ' x ' + item.quantity;
            }

            if (item.marker !== 'no-receipt') return name;
        } else if (imap && item.quantity === 0) {

            let result = null;
            const i: Item = imap[item.id];

            if (i && i.absentId) {

                const absent: Item = imap[i.absentId];

                if (absent) {
                    const name = absent.name;

                    result = name;
                } else {
                    console.log('absent item not found', i.absentId);
                }
            }

            return result;

        }

        return null;
    }

    addClicked(item: Item, group: ItemGroup, detectChanges: boolean = true, isPreselect: boolean = false) {
        // to do
        // this.googleAnalyticEventTrack("add-item", item.name);
        let groupId = group.id;
        if (this.noNeedConfirm && this.noNeedConfirm[groupId]) delete this.noNeedConfirm[groupId];
        if (this.isSoldout(item)) return;

        this.add(item, group, isPreselect);
        this.handleModifier();
        if (detectChanges) {
            this.cd.detectChanges();
        }
        console.log('build', this.builder);
        // fixed: 取消選擇後, css 要回到未選擇時的style
        // this.checkingGroupCompleted(this.getItemRealGroupIndex(item));

    }

    groupSelected(code: string) {
        if (!this.isGroupExpand[code]) {
            this.isGroupExpand[code] = true;
            setTimeout(() => {
                //this.preOpenTitleGroup(code, true);

            }, 100);
        }
        else {
            this.isGroupExpand[code] = false;
            this.openedTitleGroup[code] = null;
            for (let key in this.isTitleGroupExpand[code]) {
                this.isTitleGroupExpand[code][key] = false;
            }
        }
        // this.cd.detectChanges();

    }

    async showNotCompletedAlert() {

        const subtitle = this.t.instant('pages.set-select.select-error');
        const confirm = this.t.instant('buttons.ok');

        const alert = await this.alertController.create({
            message: subtitle,
            buttons: [confirm]
        });
        alert.present();
    }

    async showQtyAlert() {

        // const subtitle = this.t.instant('pages.set-select.select-error');
        const subtitle = 'QTY must greater than 0';
        const confirm = this.t.instant('buttons.ok');

        const alert = await this.alertController.create({
            message: subtitle,
            buttons: [confirm]
        });
        alert.present();
    }

    async presentModifierPopup(store: Store, orderItem: OrderItem, group: ItemGroup, itemMap: any, page: any, handler?: any, showLastOne?: boolean) {
        // let options = {showBackdrop: true, backdropDismiss: true, cssClass: 'modal-largescreen'};
        const input = { store: store, orderitem: orderItem, group: group, itemMap: itemMap };
        if (showLastOne) {
            input['showLastOne'] = showLastOne;
        }
        const modal = await this.modalController.create({
            component: page,
            componentProps: input,
            animated: true,
            showBackdrop: false,
            backdropDismiss: true,
            cssClass: 'modal-largescreen modifier-modal ',
            enterAnimation: popUpAnimation,
            leaveAnimation: popDrownAnimation
        });
        modal.onDidDismiss().then((confirm) => {
            if (handler) {
                handler(confirm);
            }
        });
        modal.present();
    }

    getSelectedItemNamesModifier(group): string {
        //    if (this.builder.groups) return ;
        let itemGroup: ItemGroup = this.builder.groups.filter(
            _group => {
                if (_group) {
                    return _group.id === group.id;
                } else {
                    return false;
                }
            }
        )[0];

        // if (itemGroup === null) {
        //     return this.t.instant("pages.item-select.no-need");
        //     // return "不需要";
        // }

        // if (typeof itemGroup === 'undefined') {
        //     return "";
        // }

        if (!itemGroup) {
            return '';
        }
        let selected = itemGroup.items;
        if (!selected) {
            return '';
        }

        // get modifier
        let firstItem = true;

        let output = '';
        for (let select of selected) {
            if (!select.mgroups) continue;

            for (let mgroup of select.mgroups) {
                if (!mgroup.items) continue;

                for (let item of mgroup.items) {
                    if (firstItem) {
                        output = item.name + ' x ' + item.quantity;
                        firstItem = false;

                    } else {
                        output = output + ', ' + item.name + ' x ' + item.quantity;
                    }
                }

                // return output;
            }
        }

        return output;
    }

    /*hasModifier(items: Item[], itemMap: any, checkRequired: boolean = false): boolean {
        let required = false;
        for (const item of items) {
            const tmpItem = itemMap[item.id];
            if (tmpItem && tmpItem.mgroups && tmpItem.mgroups.length > 0) {
                if (checkRequired) {
                    for (const mgroup of tmpItem.mgroups) {
                        if (mgroup.min && mgroup.min > 0) {
                            required = true;
                            break;
                        }
                    }
                } else {
                    required = true;
                    break;

                }

            }
        }
        return required;
    }*/

    isRequiredModifierSelect(index: number): boolean {
        const oi = this.currentOrderItem ? this.currentOrderItem : this.builder.build();
        this.currentOrderItem = oi;
        let currentGroup = this.nonAutoPickupGroups[index];
        let tmpIndex = -1;
        currentGroup && oi.groups.map((el, idx) => {
            if (currentGroup.id === el.id)
                tmpIndex = idx;
        });
        const group = oi.groups[tmpIndex];
        if (group && this.hasRequiredModifier(group.items)) {

            const builderItems: Item[] = group.items;
            const selected = builderItems.every(item => {
                const builderMgroups: ItemGroup[] = item.mgroups;// 选中的 mgroups, mgroups.items 只包含选中的item
                const mgroups: ItemGroup[] = this.itemMap[item.id].mgroups; // 选中的 mgroups 对应的完整的mgroups信息, mgroups.items 包含所有的item

                let currentMgroupCompleted = true;
                if (mgroups) {
                    for (let i = 0; i < mgroups.length; i++) { // 完整mgroups信息下标跟选中的mgroups下标不一致

                        let builderMgroupIndex = -1;
                        builderMgroups && builderMgroups.map((el, idx) => {
                            if (mgroups[i].id === el.id)
                                builderMgroupIndex = idx;
                        });
                        if (builderMgroups && builderMgroups[builderMgroupIndex] && mgroups[i].min > 0) {
                            // 配置过,用配置的qua 与 完整mgroups数据的min比较
                            let quantity = 0;
                            for (const t of builderMgroups[builderMgroupIndex].items) {
                                quantity += 1 * t.quantity;
                            }
                            currentMgroupCompleted = (quantity >= mgroups[i].min);

                        } else if ((!builderMgroups || !builderMgroups[builderMgroupIndex])) {
                            //未配置过，看完整mgroups数据的min
                            currentMgroupCompleted = !(mgroups[i].min > 0);
                        }
                        if (!currentMgroupCompleted) break; // 出现过false 就是有某一mgroups 没符合配置最低的数量要求
                    }
                }
                return currentMgroupCompleted;


            });
            return selected;
        } else {
            return true;
        }
    }

    isModifiable(item: Item): boolean {
        if (!this.modifiableMap[item.id]) {
            this.modifiableMap[item.id] = Item.isModifiable(item);
        }
        return this.modifiableMap[item.id];
    }

    private isRequiredModifier(item: Item): boolean {
        if (this.isModifiable(item)) {
            if (!this.requiredModifierMap[item.id]) {
                this.requiredModifierMap[item.id] = item.mgroups.some(mgroup => mgroup.min > 0);
            }
            return this.requiredModifierMap[item.id];
        } else {
            return false;
        }
    }
}



