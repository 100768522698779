import { UUID } from 'angular2-uuid';
import { ConfigService } from './config.service';
import * as i0 from "@angular/core";
import * as i1 from "./config.service";
var SessionService = /** @class */ (function () {
    function SessionService(configService) {
        this.configService = configService;
        // restore session here
        this.session = configService.getLocal('byod.session');
    }
    SessionService.prototype.getSession = function () {
        return this.session;
    };
    SessionService.prototype.getTodaySession = function () {
        var session = this.getSession();
        if (!session) {
            return null;
        }
        /*
        var today = this.datePipe.transform((this.menuManager.debugTime ? new Date(this.menuManager.debugTime) : new Date()), 'yyyy-MM-dd');
        if(today == session.date){
            return session;
        }*/
        return null;
    };
    SessionService.prototype.setSession = function (session) {
        this.session = session;
        this.configService.setLocal('byod.session', session);
    };
    SessionService.prototype.getDeviceId = function () {
        var did = this.configService.getLocal('deviceId');
        if (!did) {
            did = UUID.UUID().toString();
            this.configService.setLocal('deviceId', did);
        }
        return did;
    };
    SessionService.ngInjectableDef = i0.defineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.inject(i1.ConfigService)); }, token: SessionService, providedIn: "root" });
    return SessionService;
}());
export { SessionService };
