import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
// delon
// i18n
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
// #region third libs
import {IonicModule} from '@ionic/angular';
import {createTranslateLoader} from './utilities/createTranslateLoader';
import {HttpClient} from '@angular/common/http';
import {GuestInfoModule} from './components/guest-info/guest-info.module';
import {AcceptMarketingModule} from './components/accept-marketing/accept-marketing.module';
import {ConfigService} from '../core/services/config.service';
import {ThemeService} from '../core/services/theme.service';
import {DirectivesModule} from './directives/directives.module';
// pipe
import {GetDateStringLabelPipe} from './pipes/getDateStringLabelPipe';
import {TimeStringLabelPipe} from './pipes/timeStringLabelPipe';
import {CustomFilterPipe} from './pipes/custom-filter.pipe';
import {ItemPricePipe} from './pipes/item-price.pipe';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {CustomBackButtonModule} from './components/custom-back-button/custom-back-button.module';
import {ThrottleClickModule} from './directives/throttle-click/throttle-click.module';
import {SortItemPipe} from './pipes/sort-item.pipe';

const THIRDMODULES = [];
// #endregion

// #region your componets & directives
const COMPONENTS = [];
const DIRECTIVES = [];
const PIPES = [GetDateStringLabelPipe, ItemPricePipe, TimeStringLabelPipe, CustomFilterPipe, SafeHtmlPipe, SortItemPipe];

// #endregion

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        GuestInfoModule,
        AcceptMarketingModule,
        DirectivesModule,
        ThrottleClickModule,
        CustomBackButtonModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient, ConfigService, ThemeService]
            }
        }),
        FormsModule,
        // third libs
        ...THIRDMODULES,
    ],
    declarations: [
        // your components
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES,
        ItemPricePipe,
        SafeHtmlPipe
    ],
    exports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule,
        GuestInfoModule, ThrottleClickModule,
        AcceptMarketingModule,
        CustomBackButtonModule,
        DirectivesModule,
        // i18n
        TranslateModule,
        // third libs
        ...THIRDMODULES,
        // your components
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES,
    ],
})
export class SharedModule {
}
