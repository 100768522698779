import {Brand, Data, Item, ItemGroup, Menu, MenuManager, Store} from 'aigens-ng-core';
import {AQuery} from '../base/aquery';
import {BaseService} from '../base/base-service';
import {ConfigService} from './config.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import * as CircularJSON from 'circular-json';
import {GoogleAnalyticsService} from "./google-analytics.service";

@Injectable({providedIn: 'root'})
export class MenuService extends BaseService {

    aq: AQuery;

    constructor(private http: HttpClient, public configs: ConfigService, private gaService: GoogleAnalyticsService, private menuManager: MenuManager) {
        super();

        this.aq = new AQuery(http, configs);
    }

    postInventory(storeId: any, item: Item, quantity: number): Observable<any> {

        const url = '/api/v1/menu/inventory.json';

        const params = {storeId: storeId, quantity: quantity};

        params['itemId'] = item.id;

        /*
        if (item.backendId) {
            params['backendId'] = item.backendId;
        } else {
            params['itemId'] = item.id;
        }*/

        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;

        return aq.auth(true).getJson().pipe(map(jo => jo['data']));
    }

    getMenus(brandId: any): Observable<Menu[]> {

        const url = '/api/v1/menu/menu.json?unknown=true&brandId=' + brandId;

        const aq = this.aq;
        aq.url = url;

        return aq.auth(true).getJson().pipe(map(jo => Data.toDataArray(Menu, jo['data'])));
    }

    getItemGroups(menuId: any): Observable<ItemGroup[]> {

        const url = '/api/v1/menu/group.json?menuId=' + menuId;

        const aq = this.aq;
        aq.url = url;

        return aq.auth(true).getJson().pipe(map(jo => Data.toDataArray(ItemGroup, jo['data'])));
    }

    getModGroups(menuId: any): Observable<ItemGroup[]> {

        const url = '/api/v1/menu/group.json?menuId=' + menuId + '&modifier=true';

        const aq = this.aq;
        aq.url = url;

        return aq.auth(true).getJson().pipe(map(jo => Data.toDataArray(ItemGroup, jo['data'])));
    }

    getItems(menuId: any): Observable<Item[]> {

        const url = '/api/v1/menu/item.json?menuId=' + menuId;

        const aq = this.aq;
        aq.url = url;

        return aq.auth(true).getJson().pipe(map(jo => Data.toDataArray(Item, jo['data'])));
    }

    /*
    getAllOfMenu(id: string, storeId: any): Observable<Menu> {
        var url = "/api/v1/menu/menu/" + id + ".json?fields=items";
        if (storeId) {
            url += "&storeId=" + storeId;
        }
        var aq = this.aq;
        aq.url = url;

        return aq.getJson().pipe(map(jo => this.processMenu(Data.toData(Menu, jo['data'])));
    }

    getMenu(id: string, storeId: any): Observable<Menu> {
        var url = "/api/v1/menu/menu/" + id + ".json";

        if (storeId) {
            url += "?storeId=" + storeId;
        }

        var aq = this.aq;
        aq.url = url;

        return aq.getJson().pipe(map(jo => this.processMenu(Data.toData(Menu, jo['data'])));
    }*/

    getBrand(brandId: number): Observable<Brand> {
        const url = '/api/v1/menu/brand/' + brandId + '.json';

        const aq = this.aq;
        aq.url = url;

        return aq.getJson().pipe(map(jo => Data.toData(Brand, jo['data'])));
    }

    /*
    private processMenu(menu: Menu): Menu {
        this.menuManager.processMenu(menu);
        return menu;
    }




    private checkIg(ig: ItemGroup, gmap: any) {

        if (!ig.items) return;

        for (let item of ig.items) {

            this.checkMods(item, gmap);
        }
    }

    private checkMods(item: Item, gmap: any) {
        var mgIds = item.mgroupIds;
        if (mgIds && mgIds.length > 0) {
            var mgs = [];
            for (let mgId of mgIds) {
                var mg = gmap[mgId];
                if (mg) mgs.push(mg);
            }
            item.mgroups = mgs;
        }
    }*/

    getCourt(courtId: number): Observable<Brand> {
        const url = '/api/v1/menu/court/' + courtId + '.json';

        const aq = this.aq;
        aq.url = url;

        return aq.getJson().pipe(map(jo => Data.toData(Brand, jo['data'])));
    }

    getStore(id: number, checkOpen: boolean = false, menuType?: string, defaultLang: string = ''): Observable<Store> {

        let url = '/api/v1/menu/store/' + id + '.json';

        if (defaultLang) {
            url += `?locale=${defaultLang}`;
        }

        if (checkOpen) {
            url += (defaultLang ? '&' : '?') + 'open=true';
        }

        if (menuType) {
            url += (checkOpen || defaultLang ? '&' : '?') + 'menu=' + menuType;
        }


        const aq = this.aq;
        aq.url = url;
        return aq.getJson().pipe(map(jo => {
            console.log('reponse', jo);
            this.configs.serverTime = jo['time'];
            return this.processStore(Data.toData(Store, jo['data']));
        }));
    }

    getBKStore(id: number, checkOpen: boolean = false, menuType?: string): Observable<Store> {
        const isPrd = window.location.host === 'bksg.order.place';
        const server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';
        let url = server + '/api/v1/menu/store/' + id + '.json';

        if (checkOpen) {
            url += '?open=true';
        }

        if (menuType) {
            url += (checkOpen ? '&' : '?') + 'menu=' + menuType;
        }


        const aq = this.aq;
        aq.url = url;

        return aq.getJson().pipe(map(jo => {
            this.configs.serverTime = jo['time'];
            return this.processStore(Data.toData(Store, jo['data']));
        }));
    }

    getInventory(storeId: number) {

        const url = '/api/v1/menu/inventory.json?storeId=' + storeId;

        const params = {};

        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;

        return this.aq.auth(true).getJson().pipe(map(jo => {
            return jo['data'];
        }));
    }

    isBetween(item: Item, now: number): boolean {


        if (!item.start && !item.end) {
            return true;
        }

        let greaterThanStart = true;
        let lessThanEnd = true;

        if (item.start) {
            greaterThanStart = now >= item.start;
        }

        if (item.end) {
            lessThanEnd = now <= item.end;
        }

        return greaterThanStart && lessThanEnd;

    }


    /*
    getFullStore(id: number): Observable<Store> {

        var url = "/api/v1/menu/store/" + id + ".json";

        var aq = this.aq;
        aq.url = url;

        return aq.getJson().pipe(map(jo => Data.toData(Store, jo['data']));
    }*/

    clone(item: Item, itemgroups: ItemGroup[], now: number): [Item, ItemGroup[]] {
        const mainItem: Item = Data.toData(Item, CircularJSON.parse(JSON.stringify(item)));
        const groups: ItemGroup[] = Data.toDataArray(ItemGroup, CircularJSON.parse(JSON.stringify(itemgroups)));

        const mainItemGroups: ItemGroup[] = [];
        const groupIds: string[] = [];

        for (const group of mainItem.groups) {

            const items: Item[] = [];

            group.items.forEach(i => {

                if (this.isBetween(i, now)) {
                    items.push(i);
                }
            });

            if (items.length > 0) {

                group.items = items;

                mainItemGroups.push(group);
                groupIds.push(group.id);

            }


        }


        if (mainItem.pgroupId) {
            mainItemGroups.push(item.pgroup);
            groupIds.push(item.pgroupId);
            groups.push(item.pgroup);
        }


        mainItem.groupIds = groupIds;
        mainItem.groups = mainItemGroups;

        return [mainItem, groups];


    }

    getActivePasses(defaultLang: any, storeId: any, spot: any, session: any = null) {

        // var url = "/api/v1/menu/pass.json?" + "locale=" + locale + "&storeId=" + storeId + "&spot=" + spot;
        let url = '/api/v1/menu/pass.json?' + 'storeId=' + storeId + '&spot=' + spot;

        if (defaultLang) {
            url += '&locale=default';
        }

        if (session) {
            url += '&session=' + session;
        }
        let aq = this.aq;
        aq.url = url;
        aq.method = 'get';

        return aq.auth(true).getJson().pipe(map(jo => jo['data']));
    }

    private processStore(store: Store): Store {

        const menu = store.menu;
        if (menu) {
            // this.processMenu(menu);

            console.log('processing menu');

            this.menuManager.processMenu(menu, 'byod');
            // Put somewhere else?
            this.gaService.setDimensions({
                'dimension3': store.groupId,
                'dimension2': store.brandId,
                'dimension1': store.id
            })
            console.log('gtag process menu');
            store.categories = menu.categories;
            if (menu.currency) {
                store.currency = menu.currency;

            }
        }

        return store;
    }

}
