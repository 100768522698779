import {BaseService} from '../base/base-service';

import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';

/**
 *  To ensure no loading overlap. No matter how many times loading(true) called, only max = 1 loading showed
 *  And any loading(false) will dismiss all loading
 *
 * */
@Injectable({providedIn: 'root'})
export class LoadingService extends BaseService {

    lastLoadingStatus = false;
    private existingLoading = false; // 唯一指示当前是否有loading的变量(大坑：由于继承和派生的原因 basepage中的变量不是唯一的)
    private loader: HTMLIonLoadingElement;

    constructor(protected loadingController: LoadingController) {
        super();
        console.log('loading service init');
    }

    async loading(show, message: string = null, refresher) {
        console.log('loading, show', show);
        const changed = this.lastLoadingStatus != show;
        if (!changed) {
            console.log('loading status unchanged, return', this.lastLoadingStatus);
            return;
        }
        this.lastLoadingStatus = show;
        // console.trace('loading called');
        if (show) {

            // if (this.refresher || this.loader) {
            // not use 异步赋值的变量来判断是否存在 loading
            if (refresher || this.existingLoading) {
                console.log('loading existing', this.existingLoading);
                console.log('loading refresher existing', refresher);
                return;
            }

            /*
            this.loader = this.loadingController.create({
                //content: 'Please wait...'
            });*/
            let tempLoader = await this.makeLoader(message);
            if (this.lastLoadingStatus) {
                tempLoader.onDidDismiss().then(() => {
                    if (this.loader || this.existingLoading == true && Object.is(this.loader, tempLoader)) {
                        this.loader = null;
                        this.setExistingLoading(false);
                        console.log('on loading did dismiss, force update status');
                    }
                });
                console.log('loading presenting');
                await tempLoader.present();
                this.setExistingLoading(true);
                // if (this.loader) {
                //     javascript is single thread so i think this is no need?
                //     console.log('loading existed during present');
                //     await tempLoader.dismiss();
                // } else {
                //     console.log('loading saved');
                // }
                this.loader = tempLoader;
                console.log('loading presented');
                if (!this.lastLoadingStatus) {
                    await tempLoader.dismiss();
                }
            } else {
                console.log('loading not present');
            }
            console.log('loading showed');

        } else {

            if (this.loader) {
                // this.loader.dismiss();
                await this.loader.dismiss();
                this.loader = null;
                this.setExistingLoading(false);
                console.log('loading found, dismissed');
            } else {
                console.log('loading not found, cannot dismiss');
            }

            if (refresher) {
                refresher.complete();
                refresher = null;
            }
            console.log('loading dismissed');


        }
    }

    private setExistingLoading(bool: boolean) {
        this.existingLoading = bool;
    }

    getExistingLoading() {
        return this.existingLoading;
    }

    async makeLoader(message: string = null): Promise<HTMLIonLoadingElement> {
        if (message == null || message.length === 0) {
            return await this.loadingController.create({});
        } else {
            return await this.loadingController.create({
                message: message
            });
        }
    }

}
